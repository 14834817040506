// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

import { registerControllers } from "stimulus-vite-helpers";
const controllers = import.meta.globEager("./**/*_controller.js");
registerControllers(application, controllers);

import { Tabs } from "tailwindcss-stimulus-components"
application.register('tabs', Tabs)
