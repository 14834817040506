import { Controller } from "@hotwired/stimulus"

export default class extends(Controller) {
  static targets = [ "clearent"]

  connect() {
    this.initializeClearentSDK();
  }

  setAccountNumber() {
  }

  setCompanyName() {

  }

  setPayeeName() {

  }

  setLocation() {

  }

  payWithClearent() {
    ClearentSDK.getPaymentToken().then(
      function(payload) {
        console.log(result)
        alert('result: ' + result);
      }
    )
  }

  initializeClearentSDK() {
    if (!this.clearentTarget) return;

    ClearentSDK.init({
      "paymentFormId": "clearent-payment-form",
      "baseUrl": this.clearentTarget.dataset.url,
      "pk": this.clearentTarget.dataset.pk
    });
  }

  ClearentTokenSuccess(raw, json) {
    console.log("ClearentTokenSuccess");
    console.log(raw);
    console.log(json);

    // now you can send the token to your server
    // to complete the transaction via mobile-gateway

  }

  ClearentTokenError(raw, json) {
    console.log("ClearentTokenError");
    console.log(raw);
    console.log(json);
  }
}
